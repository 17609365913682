import React, {useEffect} from "react"

import { Helmet } from "react-helmet"

import Favicon from '../images/icon.png'
import socialImage from '../images/social.jpg'

import {Link} from "gatsby"


// markup
const PrivacyPolicy = () => {

    useEffect(() => {
        document.body.setAttribute("style","Overflow:unset")
      }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy | The ABC’s of Survival</title>
        <meta name="description" content="Privacy Policy | The ABC's of Survival" />
        <link rel="icon" type="image/png" href={Favicon}></link>
        <meta property="og:title" content="The Book That Shouldn’t Exist | The ABC’s of Survival" />
        <meta property="og:image" content={"https://abcsofsurvival.com" + socialImage} />
      </Helmet>
    <div className="disclosure">
         <h4>
            <Link className="back-home" to="/">&lt;&nbsp;&nbsp;<span>BACK</span></Link>
        </h4>
        <div className="content">

        <h1>Privacy Policy</h1>
        <h4>PRIVACY POLICY</h4>

        <h4>THIS PRIVACY POLICY IS EFFECTIVE AS OF MARCH 8, 2021</h4>
        <p>Thank you for visiting the website for The CementWorks, LLC d/b/a The Bloc ("Bloc," "we," "us," or "our"), maintained at www.thebloc.com (the "Site"). Your privacy is important to us. Bloc is committed to protecting our users' information and to making sure that our users are aware of our practices regarding the privacy and security of their information. We want you to understand what information we collect from you, how we handle that information, and how you can contact us to correct any information that we may have collected.</p>
        <h4>WHAT THIS PRIVACY POLICY ENTAILS</h4>
<p>This Privacy Policy guides our collection, storage, and use of information, including personally-identifiable and non-personally-identifiable information collected on the site and through other electronic messages between you and this Site or you and Bloc. <strong>By accessing the Site, you expressly consent and agree to the terms and conditions of this Privacy Policy, including the use and disclosure of your personal information in accordance with this Privacy Policy. If you do not agree, then please do not access the Site.
You also agree that you are at least eighteen (18) years old and have agreed to this Privacy Policy.</strong></p>
<h4>CHANGES TO THIS PRIVACY POLICY</h4>
<p><strong>Any or all of this Privacy Policy may be changed at any time without notice being sent to you, in which event the revised Privacy Policy will be posted with an updated effective date. Your access or use of the Site after the posting of such changes shall mean that you accept such changes. Therefore, each time you use or access this Site, you should review the Privacy Policy.</strong></p>
<h4>PERSONALLY-IDENTIFIABLE INFORMATION OF CHILDREN AND INDIVIDUALS UNDER THE AGE OF 18</h4>
<p>This Site is not intended for children and individuals under the age of eighteen (18). Bloc does not knowingly solicit or collect personally-identifiable information from children or individuals under the age of 18. If Bloc knows that it has collected or received personal information from a child or individual under the age of 18, we will take reasonable measures to delete such information from our servers and databases and to not use such information for any purpose (except where necessary to protect the safety of the child or others as required by law). If you believe that Bloc might have any information from or about a child or individual under the age of 18, please contact Bloc here: <a href="mailto:info@thebloc.com">info@thebloc.com</a>.</p>
<h4>INFORMATION BEING COLLECTED AND DATA INTEGRITY</h4>
<p>Bloc only collects personally-identifiable and non-personally-identifiable information that is relevant for the purposes for which it is to be used, to the extent reasonably necessary for legitimate business purposes, and in accordance with this Privacy Policy. Bloc will take reasonable steps to ensure that personally-identifiable information is collected for purposes relevant to its intended use. If you choose to provide us with personally identifiable information, you consent to the transfer and storage of that information on our (or our third-party hosts') servers.</p>
<p>You can browse this Site and download information from this Site without providing Bloc with any personal information about yourself.</p>
<p>Please note that Bloc may automatically collect the following non-personally identifiable-information from you through your use of the Site:</p>
<ul>
<li>Details of your visits to the Site, which may include the internet domains or IP addresses from which the Site is accessed, the date and time the Site is accessed, the location from which the site is accessed, the pages that are visited, and the address of the website that linked to the Site; and</li>
<li>Information about your computer or device used to access the Site and internet connection, which may include the IP address from which the Site is accessed, the operating system, and the type of browsers used to access the Site</li>
</ul>
<p>Bloc may also use "cookies" on the Site, which are elements of data that a website can send to a user's browser and store on a user's computer. Bloc may also use "web beacons," which are typically images used to pass information from your computer to a website or server. Cookies and web beacons can note that a user's browser was used to visit certain sites or pages on a certain date. They can also note your use of the Site and assist you with navigation during a use session. The information about your usage of the Site collected through cookies may be stored on our servers or on the servers of our third-party providers. You may opt out of the use of cookies (and web beacons when used in connection with cookies) by activating the appropriate setting on your browser. If you elect to opt out of cookies and web beacons, it may affect your ability to use the Site.
The information Bloc collects on or through the Site may also include information that you voluntarily submit to Bloc, which may include:</p>
<ul>
<li>Records and copies of your correspondence (including email addresses) if you contact Bloc via the Site, email, written correspondence, or other means;</li>
<li>Information that you provide by filling in any forms on the Site; and</li>
<li>Other information received in the course of such communication</li>
</ul>
<h4>
HOW THE INFORMATION WE COLLECT FROM YOU IS USED
</h4>
<p>Bloc may use the information we collect from you or that you provide to us, including personally-identifiable information:</p>
<ul>
<li>To present this Site and its contents to you;</li>
<li>To analyze the Site and its usage;</li>
<li>To help us improve the Site and make it more useful to our users and customers;</li>
<li>To provide you with information, products, or services that you request from us</li>
<li>To provide you with educational and promotional information, materials, and services;</li>
<li>To fulfill any other purpose for which you provide it or for our business purposes;</li>
<li>To evaluate whether or not you should be contacted by one of our representatives who can provide you with additional information about our products and services;</li>
<li>In any other way that Bloc may describe to you when you provide the information; and</li>
<li>For any other purpose with your consent</li>
</ul>
<p><em>Bloc may also use your information to contact you about Bloc's products and services or the products and services of our affiliates that may be of interest to you. If you do not want Bloc to use your information in this way, please follow the unsubscribe instructions provided in such communications or send an email with the subject heading "Unsubscribe" to <a href="mailto:info@thebloc.com">info@thebloc.com</a>.</em></p>
<h4>DISCLOSURE OF INFORMATION</h4>
<p>Bloc may provide your personally-identifiable information to our service providers as necessary. We may also provide your personally-identifiable information in response to a verified request by law enforcement or other government officials relating to a criminal investigation or alleged illegal activity or any other activity that may expose us, you, or any other user of the Site to legal liability. We also can release your personally-identifiable information in connection with an investigation of fraud, theft, intellectual property infringement, piracy, or other unlawful activity or activity that violates this Privacy Policy or our Terms of Use. We may also disclose your personally-identifiable information in connection with an onward transfer (i) with respect to a merger or acquisition of Bloc or sale of assets or the business with respect to the Site; or (ii) to a third party working on behalf of or providing services to Bloc.</p>
<p>Other than as set forth in this Privacy Policy, Bloc does not share, rent, or sell any personally-identifiable information that we collect from you with any third parties other than our affiliates.</p>
<p>Notwithstanding the foregoing, Bloc may share or disclose non-personally-identifiable information, whether aggregated or otherwise, with third parties.</p>
<h4>CORRECTING INFORMATION</h4>
<p>You may update or change information relating to your use of this Site by sending an email to info@thebloc.com. Bloc may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>


<h4>HOW LONG WE KEEP YOUR PERSONALLY-IDENTIFIABLE INFORMATION</h4>
<p>
Bloc retains the personally-identifiable information you provide us as reasonably necessary in connection with Bloc's operation of its business and for you to use the Site, or for us to provide customer support or other services in connection with the Site.</p>
<h4>SECURITY</h4>
<p>Bloc uses reasonable safeguards to protect the security, integrity, and privacy of personally-identifiable information provided to Bloc by users of the Site.</p>
<p><strong>ALTHOUGH BLOC USES REASONABLE SECURITY PRECAUTIONS WITH RESPECT TO YOUR PERSONALLY-IDENTIFIABLE INFORMATION STORED ON OUR SERVERS, BLOC CANNOT GUARANTEE THAT ANY OF YOUR PERSONALLY-IDENTIFIABLE INFORMATION STORED ON OUR SERVERS OR TRANSMITTED TO OR FROM THE SITE WILL BE FREE FROM UNAUTHORIZED ACCESS. TO THE FULLEST EXTENT NOT PROHIBITED BY LAW, BLOC DISCLAIMS AND SHALL HAVE NO LIABILITY FOR ANY LOSS OF, UNAUTHORIZED ACCESS OR DAMAGE TO, OR INTERCEPTION OF ANY OF YOUR PERSONALLY-IDENTIFIABLE INFORMATION BEING STORED ON OUR SERVERS OR ON THE SITE OR TRANSMITTED TO OR FROM THE SITE.</strong></p>
<h4>OPT-OUT</h4>
<p>At any time should you wish for us to no longer communicate with you, or wish to have us delete or destroy any names, email addresses, content, or communications you send to us, please write to us at: The CementWorks, LLC d/b/a The Bloc, 32 Old Slip, 15th Floor, New York, NY 10005 or email us at <a href="mailto:info@thebloc.com">info@thebloc.com</a>.</p>
<h4>GOVERNING LAW; JURISDICTION; WAIVER OF JURY TRIAL</h4>
<p>By using the Site, you agree that the laws of the State of New York, without regard to principles of conflict of laws, will govern this Privacy Policy and any dispute of any sort that might arise between you and us, including, without limitation, any tort claims. Any such dispute shall be adjudicated in the federal and state courts in New York, New York, and you consent to exclusive jurisdiction and venue in such courts and waive any and all rights to claim inconvenient forum or immunity. You expressly agree hereby, to the fullest extent permitted by law, to waive the right to a trial by jury.</p>
<h4>CALIFORNIA RESIDENTS–YOUR PRIVACY RIGHTS</h4>
<p>California Civil Code § 1798.83 permits users of this Site that are California residents to request certain information about the disclosure of personal information to third parties for their direct marketing purposes during the preceding calendar year. To request a copy of the information disclosure provided by Bloc pursuant to § 1798.83 of the California Civil Code, please send an email to <a href="mailto:info@thebloc.com">info@thebloc.com</a>. Please allow thirty (30) days for a response.</p>
<h4>BINDING AGREEMENT</h4>
<p>You agree that the limitation of liability provision contained in the Terms of Use and in this Privacy Policy applies to any and all disputes and claims arising out of or relating to this Privacy Policy, your use of the Site, and any information you provide to Bloc.</p>
<h4>THIRD PARTY LINKS</h4>
<p>There may be links on this Site to third party sites.  When you link to a third party's site, all information collected by such third party is subject to such third party's privacy policy and not to the Bloc's Privacy Policy.</p>
<h4>QUESTIONS ABOUT OUR PRIVACY POLICY</h4>
<p>Should you have any questions about our Privacy Policy, please contact us by writing to:<br/>
The CementWorks, LLC d/b/a The Bloc<br/>
32 Old Slip, 15th Floor<br/>
New York, NY 10005<br/>
or email us at <a href="mailto:info@thebloc.com">info@thebloc.com</a>.</p>




        </div>
    </div>

      <div>

      </div>

    </>
  )
}

export default PrivacyPolicy
