import React from "react"
import Family from "../images/Shamel_and_Family.png"

const About = (props) => {

  return (
    <div id="about-container" className={`section about ${props.display}`} data-section="2">
      <div className="container">

        <div className="family-img">
          <img className="img-fluid" src={Family} alt=""/>
        </div>
        <div className="content">
          <div className="text">
            <p>Born from the creative minds of parents, Shamel and Tiffany Washington, in the wake of the tragic events of the summer of 2020, <em>The ABC’s of Survival</em> makes “the talk” tangible. The book, 8 months in the making, debuted at the end of Black History Month, 2021. <br/><span className="m-p-t-10"> Every part of the book was created with the help of Black parents and BIPOC experts from different fields, including Allyson Jones, head of community mobilization and resource development at the National Black Child Development Institute.</span></p>
          </div>
        </div>
        <div className="letter-container">
          <div className="about-letter">A</div>
        </div>
      </div>
     
    </div>
  )
}

export default About
