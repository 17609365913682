import React from "react"
import VideoBackground from './videoBackground'
import ScrollArrow from '../images/scroll-arrow.png'

const Home = (props) => {
  

  return (
    <div className={`section video-bg ${props.display}`} data-section="1">
      <VideoBackground />
      <div className="video-context-overlay">
        <div>
          <button href="#about" className="scroll-btn desktop" onTouchStart={props.onClickMenuSwipe} onClick={props.onClickMenuSwipe} data-number="1" data-value="about" ><span>scroll</span> <img className="scroll-arrow" src={ScrollArrow} alt=""/> </button>
          <a href="#about" data-value="about" onClick={props.onClickMenu} data-value="about" className="scroll-btn mobile"><span>scroll</span> <img className="scroll-arrow" src={ScrollArrow} alt=""/></a>
        </div>
      </div>
    </div>
  )
}

export default Home
