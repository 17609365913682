import React from "react"

const FloatingLogo = ({onClickMenuSwipe, onClickMenu, onTouchLogo}) => {
  

  return (
    <>
        <div onTouchStart={onTouchLogo} onTouchEnd={onTouchLogo} className="floating-logo">
          <button className="home-btn"  onClick={onClickMenuSwipe} data-number="0" data-value="home" id="homemenu" />
          <a href="#home" onClick={onClickMenu} className="home-link" data-number="0" data-value="home" id="homemenu" />
        </div>
    </>
  )
}

export default FloatingLogo
