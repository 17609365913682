import React from "react";
import {useMediaQuery} from 'react-responsive'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

const VideoBackground = () => {

  return (
  <div className="video-wrapper">
    <Desktop>
      <iframe
    id="videoBG"
      src="https://player.vimeo.com/video/518668817?background=1"
      width="100%"
      height="100%"
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title="home video background"
    ></iframe>
    </Desktop>
    <Mobile>
      <iframe
    id="videoBG"
      src="https://player.vimeo.com/video/518668845?background=1"
      width="100%"
      height="100%"
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title="home video background"
    ></iframe>
    </Mobile>
    
  </div>
);
}

export default VideoBackground;
