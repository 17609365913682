import React from "react"

import Footer from "./footer.jsx"

import nbcdi from '../images/NBCDI_LOGO.png'

const Support = (props) => {

  return (
    <div id="support-container" className={`section support ${props.display}`} data-section="5">
        <div className="container">
        <div className="logo-container"><img src={nbcdi} alt="NBCDI Logo"/></div>
          <h2>
          THE NATIONAL BLACK CHILD DEVELOPMENT INSTITUTE
          </h2>
          {/* <div className="divider-container">
            <div className="divider"></div>
          </div> */}
          <div className="content-container">
            <p className=""><em>The ABC’s of Survival</em> was created in partnership with the National Black Child Development Institute (NBCDI). For the last 50 years, the nonprofit organization has been a trusted partner in developing and delivering strengths-based, culturally relevant, evidence-based, and trauma-informed resources that respond to the unique needs of Black children. </p>
            <p>Focused on issues including early childhood education and wellness, NBCDI is engaged in federal and state advocacy in the pursuit of a successful future for all children.</p>
          </div>
          <div className="btn-container">
          <div className="col-1">
            <h3>support the cause</h3>
            <p>Please donate $10 to the NBCDI to get the e-book and support their incredible work.</p>
            <a href="https://national-black-child-development-institute-inc.networkforgood.com/projects/9453-support-our-work" target="_blank" rel="noreferrer" className="cta-btn">donate</a>
          </div>
            <div className="col-2">
            <h3>get the digital book</h3>
            <p>After you donate, please download the e-book and help create change in your community.</p>  
              <a href="/ABCsOfSurvival_DigitalVersion.pdf" target="_blank" rel="noreferrer" className="cta-btn download-btn">download</a></div>
          
          </div>
          <Footer/>
        </div>
        <div className="letter-container">
          <div className="support-letter">S</div>
        </div>
    </div>
  )
}

export default Support
