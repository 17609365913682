import React, {useEffect, useState} from "react"
import $ from 'jquery'
import {Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Scrollbar, EffectFade, Mousewheel, Pagination, HashNavigation } from 'swiper';
import { Helmet } from "react-helmet"
import Vimeo from '@vimeo/player';
import {useMediaQuery} from 'react-responsive'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import FloatingLogo from '../components/floatingLogo'
import Favicon from '../images/icon.png'
import socialImage from '../images/social.jpg'

import Menu from '../components/menu'

import Home from '../components/home'
import About from '../components/about'
import Support from '../components/support'
import Credits from '../components/credits'
import Book from '../components/book'

SwiperCore.use([Navigation, EffectFade, Mousewheel, Scrollbar, Pagination, HashNavigation]);

// markup
const IndexPage = () => {
  ///Use effect function runs whenever the page completes load
  useEffect((swiper) => {
    
    /// intitializes and cleans up keyboard listener
    initializeKeyboardListener(swiper);
    return removeKeyboardListener(swiper)
    
  })

  const [audioPlay, setAudioPlay] = useState(false);
  const [audioMute, setAudioMute] = useState(true);

  const isDesktop = useMediaQuery({ minWidth: 992 })

    ////////// adds an event listener to the document which handles keys up and down for transition. 
    const initializeKeyboardListener = (swiper) => {
    document.addEventListener("keydown",function(e,swiper){handleKeyboard(e,swiper)}) 
   }
  
  const removeKeyboardListener = (swiper) => {
    
    document.removeEventListener("keydown", function(e,swiper){handleKeyboard(e,swiper)})
  }

  const handleKeyboard = (e,swiper) =>{
    if (e.keyCode == "39" || e.keyCode == "40"){
      swiper.slideNext(1000)
    }
    else if (e.keyCode == "37" || e.keyCode == "38"){
      swiper.slidePrev(1000)

    }
  }

  const closeMenu = () => {
    document.getElementById('navi-toggle').checked = false;
  }

  const onClickMenuSwipe = (e) => {

    var btnlink = e.target;
    var btn = btnlink.closest('button');
    var selectedNumber = parseInt(btn.getAttribute("data-number"));

    var link = $(`.swiper-pagination-clickable > .swiper-pagination-bullet:eq(${selectedNumber})`);

    link.trigger('click');
    closeMenu();
  }

  const onClickMenu = (e) => {
    var btnlink = e.target;
    var link = btnlink.closest('a');
    var selectedValue = link.getAttribute("data-value") + '-container';
   
    // disable get e-book button when in support section
    if(link.getAttribute('data-value') === 'support') {
      setTimeout(()=> {
        document.querySelector('.book-link').classList.add('disable', 'active');
      }, 200)
    }

    const anchor = document.getElementById(selectedValue);
    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    closeMenu();
  }

  const onTouchLogo = (e) => {
    let logo = e.currentTarget.children[1]
    logo.classList.contains("touched") ?  logo.classList.remove("touched") :  logo.classList.add("touched")
  }

  const handleInputChange = (e) => {

    let targetChecked = e.target.checked;
    const body = document.querySelector('body');
    let deskCheck = window.innerWidth > 991
    let checked = deskCheck && targetChecked
    checked ? body.classList.add('no-scroll') : body.classList.remove('no-scroll');
  }

  const checkActiveSlide = () => {
      const active = document.querySelector('.swiper-slide-active');
      var activeSupport = active.dataset.hash;
      const homeLink = document.querySelector('.floating-logo');
      const orangeLine = document.querySelector('.orange-underline');
      const ebook = document.querySelector('.book-btn');

      if(activeSupport === 'support') {
        ebook.classList.add('disable', 'active');
        ebook.disabled = true;

      } else {
        ebook.classList.remove('disable', 'active');
        ebook.disabled = false;
      }

      if(activeSupport === 'home') {
        homeLink.style.width = '0';
        orangeLine.style.opacity = '0';
      } else {
        homeLink.style.width = '140px';
        orangeLine.style.opacity = '1';
      }
  }
{/*This transition deafen function stops slide transitions for 1 second in order to remove possibility of multiple slide transitions in one swipe
the slide reset forces the slide to re-instantiate itself after every transition to prevent any bugs from spamming the transition button while the function is running.
it is called in the swiper component.  
*/}
  const transitionDeafen = (swiper) =>{
    swiper.allowSlideNext = false ; 
    swiper.allowSlidePrev = false ; 

    setTimeout(() =>{
      swiper.allowSlideNext = true ; 
      swiper.allowSlidePrev = true ; 
      swiper.slideReset(0, false)
    }, 1000)

    setTimeout(() =>{
      checkActiveSlide();
    }, 800)

  }

  const loadSwiper = (swiper) => {
    setTimeout(() =>{
      if(isDesktop) {
        checkActiveSlide();
      }
    }, 800)
  }

  const activePlay = () => {
    setAudioPlay(true);
  }

  const muteAudio = () => {
    const audioIcon = document.querySelector('.music-menu');

    if(audioPlay === false){
      setAudioPlay(true);
      const playBtn = $('.rhap_play-pause-button');
      playBtn.trigger('click');
    }
    const muteBtn = $('.rhap_button-clear.rhap_volume-button');
    //document.querySelector('.rhap_volume-button').click();

    muteBtn.trigger('click');

    audioMute ? audioIcon.classList.add('audio-active') : audioIcon.classList.remove('audio-active')
    audioMute ? setAudioMute(false) : setAudioMute(true)

  }

  const checkSection = () => {
    var scrollTop = $(window).scrollTop();
    let supportLocation = $("#support").offset().top;
    let aboutLocation = $("#about").offset().top;
    const ebookLink = document.querySelector('.book-link');
    const mainLogo = document.querySelector('.floating-logo');

    

    //disable get e-book button when in support section
    if (supportLocation < scrollTop) {
      ebookLink.classList.add('disable', 'active');
      ebookLink.removeAttribute('href');

    } else {
      ebookLink.classList.remove('disable', 'active');
      ebookLink.setAttribute("href", "#support");
    }

    if (aboutLocation < scrollTop) {
      mainLogo.style.width = '140px';
      } else {
        mainLogo.style.width = '0px';
      }
  }

  useEffect(() => {
    checkSection();
    window.addEventListener("scroll", checkSection);
  }, []);


  return (
    <>
      <Helmet>
        <title>The Book That Shouldn’t Exist | The ABC’s of Survival</title>
        <meta name="description" content="Making “the talk” tangible, this book — entirely made by BIPOC artists — is designed to be torn apart to take action. Created in partnership with The National Black Child Development Institute, Black parents, and BIPOC experts, The ABC’s of Survival makes talking about Black issues in America a conversation that sparks change and supports mental health." />
        <link rel="icon" type="image/png" href={Favicon}></link>
        <meta property="og:title" content="The Book That Shouldn’t Exist | The ABC’s of Survival" />
        <meta property="og:image" content={"https://abcsofsurvival.com" + socialImage} />
      </Helmet>
    <FloatingLogo
      onTouchLogo={onTouchLogo} 
      onClickMenuSwipe={onClickMenuSwipe} onClickMenu={onClickMenu}/>
        <Menu closeMenu={closeMenu} onClickMenuSwipe={onClickMenuSwipe} onClickMenu={onClickMenu} handleInputChange={handleInputChange} muteAudio={muteAudio}/>
      <div className="orange-underline">
        &nbsp;
      </div>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        mousewheel={true}
        direction="vertical"
        longSwipes={true}
        longSwipesMs={300}
        longSwipesRatio={0.25}
        touchRatio={0}
        allowTouchMove={false}
        shortSwipes={false}
        speed={1000}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        hashNavigation={true}
        onSlideChange={(swiper) => transitionDeafen(swiper)}
        onSwiper={(swiper) => loadSwiper(swiper)}
      >




        <SwiperSlide  data-hash="home"><Home style={{paddingBottom:"100px"}} onClickMenuSwipe={onClickMenuSwipe} /></SwiperSlide>
        <SwiperSlide  data-hash="about"><About style={{paddingBottom:"100px"}} /></SwiperSlide>
        <SwiperSlide  data-hash="book"><Book style={{paddingBottom:"100px"}} /></SwiperSlide>
        <SwiperSlide  data-hash="credits"><Credits style={{paddingBottom:"100px"}} /></SwiperSlide>
        <SwiperSlide  data-hash="support"><Support style={{paddingBottom:"100px"}} /></SwiperSlide>
      </Swiper>


      <div className="mobile-scroll">
      <div id="home-container">
      <Home style={{paddingBottom:"100px"}} onClickMenuSwipe={onClickMenuSwipe} onClickMenu={onClickMenu} />
        </div>
        
        <div id="about">
          <About style={{paddingBottom:"100px"}} />
        </div>
        <div id="book">
        <Book style={{paddingBottom:"100px"}} />
        </div>
        <div id="credits">
        <Credits style={{paddingBottom:"100px"}} />
        </div>
        <div id="support">
        <Support style={{paddingBottom:"100px"}} />
      </div>
      
      
      
      </div>
      <AudioPlayer
        autoPlay
        muted={audioMute}
        loop
        src="/ABCs_Music.mp3"
        onPlay={activePlay}
      />

    </>
  )
}

export default IndexPage
