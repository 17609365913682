import React from "react"
import book from "../images/TransparentBook.png"
import mobileBook from "../images/MobileTransparentBook.png"

const Book = (props) => {

  return (
    <div id="book-container" className={`section book ${props.display}`} data-section="3">
      <img className="book-img desktop" src={book} alt=""/>
      <img className="book-img mobile" src={mobileBook} alt=""/>
          <div className="content">
            
          <div className="text">
            <h2>THE BOOK THAT SHOULDN'T EXIST</h2>
            <p><em>The ABC’s of Survival</em> is an alphabet of lessons for BIPOC youth on how to survive—with tear-out protest posters, and postcards to send to Congress. It isn’t enough to read the lessons in this book, just as important are the conversations and change it&nbsp;sparks.</p>
          </div>
        </div>
        <div className="letter-container">
          <div className="book-letter">B</div>
        </div>
    </div>
  )
}

export default Book
