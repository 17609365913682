import React from "react"
import { Link } from "gatsby"



const Footer = (props) => {
  

    return (
        <div className="footer content">
            <div className="text">
                <p>
                    ©️2021 The CementWorks, LLC. DBA The Bloc. All Rights Reserved. <Link to="/terms-of-use" >Terms of use</Link> | <Link to="/privacy-policy" >Privacy policy</Link>
                </p>
            </div>
    
        </div>
    )
  }
  
  export default Footer
  