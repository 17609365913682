import React from "react"

const Credits = (props) => {

  return (
    <div id="credits-container" className={`section credits ${props.display}`} data-section="4">
        <div className="container">
          <h2>
            26 LETTERS. 26 LESSONS.
            8 TEAR-OUT PAGES TO TAKE ACTION.
            ORIGINAL ART AND TYPOGRAPHY FROM BIPOC ARTISTS AROUND THE&nbsp;WORLD.
          </h2>
          <div className="divider-container">
            <div className="divider"></div>
          </div>
          <div className="list-container">
            

          Antoinie Eugene<br/>
          Ciro Schu<br/>
          Collis Torrington<br/>
          Gabriel Ribeiro<br/>
          Geronna Lyte<br/>
          Graph Atik<br/>
          Jamar Callender<br/>
          Joshua Darden<br/>


          Junno Senna<br/>
          Kingsley Nebechi<br/>
          Linoca Souza<br/>
          Marie Hyon<br/>
          Mia Saine<br/>
          Oga Mendonça<br/>
          Parker Nia-Gordon<br/>


          Quincy Sutton <br/>
          Robinho Santana <br/>
          Sabrena Khadija <br/>
          Sean Waltrous<br/>
          Shamel Washington <br/>
          Tiffany Washington<br/>
          Toonky Berry <br/>
          Tré Seals
                
          </div>
        </div>
        <div className="letter-container">
          <div className="credits-letter">C</div>
        </div>
    </div>
  )
}

export default Credits
