import React from "react"

const Menu = ({onClickMenu, closeMenu, onClickMenuSwipe, handleInputChange, muteAudio, gotoSupport}) => {
  

  return (
    <div className="main-menu">
        <a onClick={muteAudio} class="music-menu"></a>
        <button onClick={onClickMenuSwipe} data-value="support" data-number="4" className="cta-btn white book-btn btn">get e-book</button>
        <a href="#support" onClick={onClickMenu} data-value="support" className="cta-btn white book-btn book-link a-link">get e-book</a>
        <a className="IG-menu" href="https://www.instagram.com/abcsofsurvival/ " rel="noreferrer" target="_blank"></a>

      <div className="navigation d-lg-none ">
        <input type="checkbox" className="navigation__checkbox" name="navi-toggle" id="navi-toggle" onChange={handleInputChange}/>
        <label htmlFor="navi-toggle" className="navigation__button">
            <span className="navigation__icon">menu</span>
        </label>

        <div className="navigation__background">&nbsp;</div>

        <nav className="navigation__nav" onClick={closeMenu}>
            <ul className="navigation__list">
                
                <li className="navigation__item">
                    <button onClick={onClickMenuSwipe} data-number="1" data-value="about" className="navigation__link btn">a<span>bout</span></button>
                    <a href="#about" onClick={onClickMenu} data-value="about" className="navigation__link a-link">a<span>bout</span></a>
                </li>
                <li className="navigation__item ">
                    <button onClick={onClickMenuSwipe} data-number="2" data-value="book" className="navigation__link btn">b<span>ook</span></button>
                    <a href="#book" onClick={onClickMenu} data-value="book" className="navigation__link a-link">b<span>ook</span></a>
                </li>
                <li className="navigation__item">
                    <button onClick={onClickMenuSwipe} data-number="3" data-value="credits" className="navigation__link btn">
                        c<span>redits</span>
                    </button>
                    <a href="#credits" onClick={onClickMenu} data-value="credits" className="navigation__link a-link">
                        c<span>redits</span>
                    </a>
                </li>
                <li className="navigation__item">
                    <button onClick={onClickMenuSwipe} data-value="support" data-number="4" className="navigation__link btn">s<span>upport</span></button>
                    <a href="#support" onClick={onClickMenu} data-value="support" className="navigation__link a-link">s<span>upport</span></a>
                </li>
            </ul>
        </nav> 
    </div>
        

    </div>
  )
}

export default Menu;
